import {
  Component,
  OnInit,
} from "@angular/core";
import {
  SafeHtml
} from "@angular/platform-browser";
import {
  HttpClient,
  HttpHeaders
} from "@angular/common/http";
import {
  PlaceDataService
} from 'src/app/services/place-data.service';
import * as data from 'src/assets/mapdata/mapdata.json';
import { checkEnv, getContentURL } from 'src/app/util/config';

@Component({
  selector: "app-place-data-links-table",
  templateUrl: "./place-data-links-table.component.html",
  styleUrls: ["./place-data-links-table.component.scss"],
})
export class PlaceDataLinksTableComponent implements OnInit {
  currentPlaceData: string;
  currentAreaData: string;
  currentPlaceYear: number;
  currentTypeData: string;
  currentYearRange: string;
  currentYoyData: string;
  firstYearRange: string;
  yoyURL: string;
  fiveYearFirstURL: string;

  fiveYearURL: string;
  public externalHtml: SafeHtml;
  serverAppendUrl = getContentURL();

  data: Object;

  urlValidationFlag: boolean;
  urlYOYFlag: boolean;
  urlFiveFirstFlag: boolean;
  urlFiveFlag: boolean;
  RegionFlag: boolean = false;

  constructor(
    private placedataservice: PlaceDataService,
    private http: HttpClient
  ) {
    this.currentPlaceYear = this.placedataservice.SurveyYear;
    this.currentYearRange = this.placedataservice.curRangeYear;
    this.firstYearRange = this.placedataservice.fstRangeYear;
    this.currentTypeData = this.placedataservice.typeName;
    this.currentPlaceData = this.placedataservice.placeName;
    this.currentAreaData = this.placedataservice.areaName;
    this.currentYoyData = this.placedataservice.yoyName;
    this.checkValidHtml();
  }

  ngOnInit(): void {
    this.loadMapSurveyData();
  }

  // YOY Analysis link for Place Data
  yoyAnalysis(typeVal, geoVal, areaVal) {

    window.open(
      `${window.location.origin}/yoy-analysis?type=${typeVal}&geo=${geoVal}`,
      "blank"
    );
  }

  // Five year Analysis link for Place Data
  fiveYearEstimates(yRange, typeVal, geoVal, areaVal) {
    if (yRange == '2009-2013' && typeVal == 'msa'){
      // console.log('year range - ',yRange, '& type - ',typeVal, '& geo - ',geoVal)
      var newGeoVal =  this.convertMSAplace(geoVal)
      // console.log('new geo - ',newGeoVal)
      window.open(
        `${window.location.origin}/five-year?type=${typeVal}&geo=${newGeoVal}&year=${yRange}`,
        "blank"
      );
    } else{
    window.open(
      `${window.location.origin}/five-year?type=${typeVal}&geo=${geoVal}&year=${yRange}`,
      "blank"
    );
   }
  }

  convertMSAplace(place) {
  
  
    var places = {
 
      'Atlanta-Sandy Springs-Roswell, GA~':'Atlanta-Sandy Springs-Marietta, GA',
      'Baltimore-Columbia-Towson, MD':'Baltimore-Towson, MD',
      'Boise City, ID':'Boise City-Nampa, ID',
      'Boston-Cambridge-Newton, MA-NH~':'Boston-Cambridge-Quincy, MA-NH',
      'Charlotte-Concord-Gastonia, NC-SC~':'Charlotte-Gastonia-Concord, NC-SC',
      'Chicago-Naperville-Elgin, IL-IN-WI':'Chicago-Naperville-Joliet, IL-IN-WI',
      'Cincinnati, OH-KY-IN~':'Cincinnati-Middletown, OH-KY-IN',
      'Cleveland-Elyria, OH':'Cleveland-Elyria-Mentor, OH',
      'Denver-Aurora-Lakewood, CO':'Denver-Aurora, CO',
      'Detroit-Warren-Dearborn, MI':'Detroit-Warren-Livonia, MI',
      'Urban Honolulu, HI':'Honolulu, HI',
      'Houston-The Woodlands-Sugar Land, TX~':'Houston-Baytown-Sugar Land, TX',
      'Indianapolis-Carmel-Anderson, IN~':'Indianapolis, IN',
      'Las Vegas-Henderson-Paradise, NV':'Las Vegas-Paradise, NV',
      'Little Rock-North Little Rock-Conway, AR':'Little Rock-North Little Rock, AR',
      'Los Angeles-Long Beach-Anaheim, CA':'Los Angeles-Long Beach-Santa Ana, CA',
      'Louisville_Jefferson County, KY-IN~':'Louisville, KY-IN',
      'Louisville/Jefferson County, KY-IN~':'Louisville, KY-IN',
      'Miami-Fort Lauderdale-West Palm Beach, FL':'Miami-Fort Lauderdale-Miami Beach, FL',
      'Nashville-Davidson-Murfreesboro-Franklin, TN~':'Nashville-Davidson-Murfreesboro, TN',
      'New Orleans-Metairie, LA~':'New Orleans-Metairie-Kenner, LA',
      'New York-Newark-Jersey City, NY-NJ-PA~':'New York-Northern New Jersey-Long Island, NY-NJ-PA',
      'Orlando-Kissimmee-Sanford, FL':'Orlando, FL',
      'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD':'Philadelphia-Camden-Wilmington, PA-NJ-DE-MD',
      'Portland-Vancouver-Hillsboro, OR-WA':'Portland-Vancouver-Beaverton, OR-WA',
      'Providence-Warwick, RI-MA~':'Providence-Fall River-Warwick, RI-MA',
      'Riverside-San Bernardino-Ontario, CA':'Riverside-San Bernardino-Ontario, CA',
      'Sacramento-Roseville-Arden-Arcade, CA':'Sacramento-Arden-Arcade-Roseville, CA',
      'San Antonio-New Braunfels, TX':'San Antonio, TX',
      'San Diego-Carlsbad, CA':'San Diego-Carlsbad-San Marcos, CA',
      'San Francisco-Oakland-Hayward, CA':'San Francisco-Oakland-Fremont, CA',
      'St. Louis, MO-IL~':'St. Louis, MO-IL',
      'Tampa-St. Petersburg-Clearwater, FL':'Tampa-St. Petersburg-Clearwater, FL'
   };
  
    // return new variable based on pre-2017 variable
    $.each(places, function (k, v) {
      //  console.log("msa" + k + ":" + v);
      if (place == k) {
        place = v;
        return false;
      }
    });
  
    return place;
  }
  checkValidHtml() {
    if (this.currentTypeData !== 'region') {
      var yoyFile = this.currentTypeData + "-unbanked-" + this.currentYoyData;
      if(this.currentTypeData == 'msa'){
        var msaFirstPlace = this.convertMSAplace(this.currentPlaceData);
        var msaPlace = msaFirstPlace.replace(/[,|~|.]/g, "").replace(/[\s|_|/]/g, "-");
       
        var fiveFirstFile = this.firstYearRange + "-msa-" + msaPlace;
        var fiveFile = this.currentYearRange + "-msa-" + this.currentYoyData;
      } else {
        var fiveFirstFile = this.firstYearRange + "-" + this.currentYoyData;
        var fiveFile = this.currentYearRange + "-" + this.currentYoyData;
      }
      
      // var fiveYearFirstURL =  `${window.location.origin}/five-year?type=${this.currentTypeData}&geo=${this.convertMSAplace(this.currentPlaceData)}&year=${this.firstYearRange}`
      // var fiveYearURL =  `${window.location.origin}/five-year?type=${this.currentTypeData}&geo=${this.currentPlaceData}&year=${this.firstYearRange}`
      yoyFile.toLowerCase();
      this.yoyURL = this.serverAppendUrl + `/analysis/household-survey/year-on-year/${yoyFile}.html`;
      //this.yoyURL = "https://s3-us-gov-west-1.amazonaws.com/cg-0ba73429-8552-4e5f-813f-f4da62ee519f" + `/year-on-year/${yoyFile}.html`; Uncmoment if yoy is needed
      this.fiveYearFirstURL = "https://s3-us-gov-west-1.amazonaws.com/cg-0ba73429-8552-4e5f-813f-f4da62ee519f" + `/five-year/documents/${fiveFirstFile}.html`;
      this.fiveYearURL = "https://s3-us-gov-west-1.amazonaws.com/cg-0ba73429-8552-4e5f-813f-f4da62ee519f" + `/five-year/documents/${fiveFile}.html`;
        const headers = new HttpHeaders({
          "Content-Type": "text/plain",
        });
    
        if (checkEnv() === 'prod') {
          const requestProd = this.http
            .get(
              this.yoyURL,
              {
                headers: headers,
                responseType: "text",
              }
            )
            .subscribe(
              (res) => {
                this.urlYOYFlag = true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlYOYFlag = false;
                this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        } else { //withCredentials: true only implemented for lower environments, not needed for prod
    
          const request = this.http
            .get(
              this.yoyURL,
              {
                headers: headers,
                responseType: "text",
                withCredentials: true,
              }
            )
            .subscribe(
              (res) => {
                this.urlYOYFlag = true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlYOYFlag = false;
                this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        }
        const headersFirst = new HttpHeaders({
          "Content-Type": "text/plain",
        });
    
        if (checkEnv() === 'prod') {
          const requestProd = this.http
            .get(
              this.fiveYearFirstURL,
              {
                headers: headers,
                responseType: "text",
              }
            )
            .subscribe(
              (res) => {
                this.urlFiveFirstFlag = true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlFiveFirstFlag = false;
                this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        } else { //withCredentials: true only implemented for lower environments, not needed for prod
    
          const request = this.http
            .get(
              this.fiveYearFirstURL,
              {
                headers: headers,
                responseType: "text",
                withCredentials: true,
              }
            )
            .subscribe(
              (res) => {
                this.urlFiveFirstFlag = true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlFiveFirstFlag = false;
                 this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        }
        const headersFive = new HttpHeaders({
          "Content-Type": "text/plain",
        });
    
        if (checkEnv() === 'prod') {
          const requestProd = this.http
            .get(
              this.fiveYearURL,
              {
                headers: headers,
                responseType: "text",
              }
            )
            .subscribe(
              (res) => {
                this.urlFiveFlag = true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlFiveFlag = false;
                this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        } else { //withCredentials: true only implemented for lower environments, not needed for prod
    
          const request = this.http
            .get(
              this.fiveYearURL,
              {
                headers: headers,
                responseType: "text",
                withCredentials: true,
              }
            )
            .subscribe(
              (res) => {
                this.urlFiveFlag= true;
                this.urlValidationFlag = true;
              },
              (error) => {
                this.urlFiveFlag = false;
                this.urlValidationFlag = false || this.urlValidationFlag;
              }
            );
        }   
 
    } else {
      this.RegionFlag = true;
    }
  }
  
  createToolTipInfoBox(data, thisyear, geotype) {

    var place = data.Geography.toUpperCase();

    var bigunb = '';
    var bigunder = '';
    var bigtotal = '';
    var ubchanged = '';

    var boundary_changed = '';

    if (thisyear == 2023) {
      bigunb = this.cleanNumber(data.Unb_23);
      bigunder = this.cleanNumber(data.Under_23);
      bigtotal = this.cleanNumber(data.Total_23);

      ubchanged = this.cleanNumber(data.Unb_Change_23);

      var sig_unb_23 = '';

      if (ubchanged != 'n/a' && ubchanged != '' && ubchanged != 'NS') {
        sig_unb_23 = data.Sig_Unb_23;
      }

      boundary_changed = data.Boundary_Change_23;

    }
    var infobox = '';
    infobox += ' <h5>' + place + '</h5>';

    infobox += '    <div class="big-stat">' + bigunb + '</div>';
    infobox += '    <div class="big-stat">Unbanked</div>';
    infobox += '    <table class="map-table-primary"><tr><td class="hdg2021"></td><th scope="col" class="hdg2021">2021</th><th scope="col" class="hdg2021">2023</th><th scope="col" class="hdg2021">Change</th></tr>';
    infobox += '      <tr class="lowlighted"><th scope="row">Unbanked</th><td>' + this.cleanNumber(data.Unb_21) + '</td><td>' + this.cleanNumber(data.Unb_23) + '</td><td>' + ubchanged + ' ' + sig_unb_23 + '</td></tr>';
    infobox += '    </table>';
    infobox += '    <div class="chart-notes"><p>NS: Not statistically significant change<br/>S: Statistically significant change</p>';
    if (place.indexOf('~') >= 0) {
      infobox += '<p>~ indicates areas with geographic boundary changes between the 2013 and 2015 surveys.</p>';
    }
    if (bigunb == '-') {
      infobox += '<p>- Indicates an estimate of zero. The population proportion may be slightly greater than zero.</p>';
    }

    return infobox;
  }


  cleanNumber(input: any): string {
    if (input == 'n/a') return 'n/a';
    if (input == 'N/A') return 'n/a';
    if (input == 'NA') return 'n/a';
    if (input == '') return 'n/a';

    return input + '%';
  }

  checkMSA(name, year) {
    if (name == "Providence-Fall River-Warwick, RI-MA") {
      name = "Providence-Fall River-Warwick, MA-RI";
    }

    while (name.indexOf('--') > 0) {
      name = name.replace('--', '-');
    }

    return 0;
  }

  loadMapSurveyData() {
    // this.chartdata = [];
    let mapdata = data;
    let name = this.currentAreaData;

    mapdata.forEach(item => {
      if (name === (item.Slug)) {

        if (item.Type == 'region') {
          var infobox = this.createToolTipInfoBox(item, this.currentPlaceYear, 'region');
          this.externalHtml = infobox;

        } else if (item.Type == 'state') {

          var infobox = this.createToolTipInfoBox(item, this.currentPlaceYear, 'state');
          this.externalHtml = infobox;
        } else if (item.Type == 'MSA') {
          var infobox = this.createToolTipInfoBox(item, this.currentPlaceYear, 'msa');
          this.externalHtml = infobox;

        }
      }

    })


  }
}